/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-promise-reject-errors */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { ACCESS_TOKEN } from '@/utils/common/common.constants';
import {
  baseApiUrl,
  penPencilClientId,
  penPencilClientType,
  penPencilApiUrl,
} from '@/utils/common/env.constants';
import { randomId } from '@pwskills/rachnaui/utils';
import { logout } from '@/utils/helper/helperFunction';
import { ClientType } from '../interfaces/api.types';

export type ResponseInterface = AxiosResponse | AxiosError;
type IClient = ClientType.BASE | ClientType.PENPENCIL;

class ApiClient {
  constructor() {
    this._get = this._get.bind(this);
    this._post = this._post.bind(this);
    this._patch = this._patch.bind(this);
    this._put = this._put.bind(this);
    this._delete = this._delete.bind(this);
  }

  private _getClient(baseURL?: string): AxiosInstance {
    const apiClient = axios.create({
      baseURL: baseURL || `${baseApiUrl}/v2`,
    });
    apiClient.interceptors.request.use(
      async (config: InternalAxiosRequestConfig) => {
        if (typeof window !== 'undefined') {
          const token = localStorage.getItem(ACCESS_TOKEN);
          // const configHeaders1 = config.headers;
          // configHeaders1['ngrok-skip-browser-warning'] = '69420';

          if (token && config.headers) {
            const configHeaders = config.headers;
            configHeaders.Authorization = `Bearer ${token}`;
          }
        }

        return config;
      },
      (err: AxiosError) => {
        return Promise.reject(err);
      }
    );

    apiClient.interceptors.response.use(
      async (response: AxiosResponse) => {
        return response.data;
      },
      (err: AxiosError) => {
        if (err.response) {
          if (typeof window !== 'undefined' && err.response.status === 401) {
            localStorage.removeItem(ACCESS_TOKEN);
            logout();
          }
          return Promise.reject(err.response.data);
        }

        return Promise.reject('Request config error');
      }
    );

    return apiClient;
  }

  private _getPenPencilClient(baseURL?: string): AxiosInstance {
    const generateRandomId = randomId();
    localStorage.setItem('randomUid', generateRandomId);
    const apiClient = axios.create({
      baseURL: baseURL || `${penPencilApiUrl}`,
    });
    apiClient.interceptors.request.use(
      async (config: InternalAxiosRequestConfig) => {
        if (config.headers) {
          const configHeaders = config.headers;
          configHeaders['Client-Id'] = `${penPencilClientId}`;
          configHeaders['Client-Type'] = `${penPencilClientType}`;
          configHeaders.randomId = generateRandomId;
        }
        return config;
      },
      (err: AxiosError) => {
        return Promise.reject(err);
      }
    );

    apiClient.interceptors.response.use(
      async (response: AxiosResponse) => {
        return response.data;
      },
      (err: AxiosError) => {
        if (err.response) {
          return Promise.reject(err);
        }
        return Promise.reject(err);
      }
    );

    return apiClient;
  }

  _get<T>(
    url: string,
    config?: AxiosRequestConfig<unknown>,
    baseURL?: string,
    type: IClient = ClientType.BASE
  ): Promise<T> {
    let get;
    if (type === ClientType.PENPENCIL) {
      get = this._getPenPencilClient().get(url, config);
    } else {
      get = this._getClient(baseURL).get(url, config);
    }
    return get as unknown as Promise<T>;
  }

  _post<T>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig<unknown>,
    baseURL?: string,
    type: IClient = ClientType.BASE
  ): Promise<T> {
    let post;
    if (type === ClientType.PENPENCIL) {
      post = this._getPenPencilClient().post(url, data, config);
    } else {
      post = this._getClient(baseURL).post(url, data, config);
    }
    return post as unknown as Promise<T>;
  }

  _patch<T>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig<unknown>,
    baseURL?: string,
    type: IClient = ClientType.BASE
  ): Promise<T> {
    let patch;
    if (type === ClientType.PENPENCIL) {
      patch = this._getPenPencilClient().patch(url, data, config);
    } else {
      patch = this._getClient(baseURL).patch(url, data, config);
    }
    return patch as unknown as Promise<T>;
  }

  _put(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig<unknown>,
    baseURL?: string,
    type: IClient = ClientType.BASE
  ): Promise<ResponseInterface> {
    let put;
    if (type === ClientType.PENPENCIL) {
      put = this._getPenPencilClient().put(url, data, config);
    } else {
      put = this._getClient(baseURL).put(url, data, config);
    }
    return put as unknown as Promise<ResponseInterface>;
  }

  _delete(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig<unknown>,
    baseURL?: string,
    type: IClient = ClientType.BASE
  ): Promise<ResponseInterface> {
    const configs = { ...config, data };
    let del;
    if (type === ClientType.PENPENCIL) {
      del = this._getPenPencilClient().delete(url, config);
    } else {
      del = this._getClient(baseURL).delete(url, configs);
    }
    return del as unknown as Promise<ResponseInterface>;
  }
}

const Client = new ApiClient();
const get = Client._get;
const post = Client._post;
const patch = Client._patch;
const put = Client._put;
const del = Client._delete;

export { get, post, patch, put, del };
