/* eslint-disable no-use-before-define */
/* eslint-disable no-prototype-builtins */
import { callKaptureAPI } from '@/api/kapture';
import UserDetailsContext from '@/context/UserDetailsContext';
import { BooleanValue, KaptureEnums, MiscellaneousEnums, RouterEnmus } from '@/GlobalEnums/enums';
import { IUserProfileDetails } from '@/interfaces/common';
import useSlugTitleMapFunction from '@/store/useSlugTitleMap';
import {
  environment,
  kaptureChatbotScript,
  kaptureDataSupportKey,
} from '@/utils/common/env.constants';
import { getCookies } from '@/utils/helper/helperFunction';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';

export default function useKapture() {
  const router = useRouter();
  const { isLoggedIn } = useContext(UserDetailsContext);
  const { slugTitleMap } = useSlugTitleMapFunction();
  const allowedRoutes = [
    '/',
    '/course/[courseSlug]',
    '/category/[categorySlug]',
    '/courses',
    '/online-degree',
    '/degree/[degreeSlug]',
    '/checkout',
  ];
  useEffect(() => {
    if (
      (router.query.hasOwnProperty(RouterEnmus.CATEGORY_SLUG) ||
        router.query.hasOwnProperty(RouterEnmus.COURSE_SLUG)) &&
      slugTitleMap // check is slug or category and slugTitleMap
    ) {
      if (router.query.hasOwnProperty(RouterEnmus.CATEGORY_SLUG)) {
        handleKaptureScript(isLoggedIn ? MiscellaneousEnums.LOGGED_IN : '', [
          RouterEnmus.CATEGORY,
          router.query.categorySlug as string,
        ]);
      } else {
        handleKaptureScript(isLoggedIn ? MiscellaneousEnums.LOGGED_IN : '', [
          RouterEnmus.COURSE,
          router.query.courseSlug as string,
        ]);
      }
    } else handleKaptureScript(isLoggedIn ? MiscellaneousEnums.LOGGED_IN : '', []);
  }, [router.isReady, isLoggedIn, slugTitleMap]);

  const kaptureCustomerAPI = async (userDetails: IUserProfileDetails) => {
    const payload = {
      contact_person_name: `${userDetails?.firstName} ${userDetails?.lastName}`,
      contact_person_phone: userDetails?.phoneNumber,
      contact_person_email: userDetails?.email,
      customer_code: userDetails?._id,
    };
    /* eslint-disable no-console */
    try {
      await callKaptureAPI(payload);
      console.log('Kapture API request succeeded');
    } catch (error) {
      console.error('Kapture API request failed:', error);
      // Handle the error as needed, e.g., show a notification to the user
    }
  };

  const handleKaptureScript = (mode: string, adHocAttribute: string[]) => {
    const script = document.getElementById('kap-snippet');
    if (script) {
      script?.parentNode?.removeChild(script);
    }
    if (!allowedRoutes.includes(router.route)) {
      const kaptureDiv = document.getElementsByClassName('faq-parent-container');
      kaptureDiv[0]?.setAttribute('style', 'display:none');
    }
    if (kaptureChatbotScript === BooleanValue.TRUE && allowedRoutes.includes(router.route)) {
      // Remove the existing script if it exists

      const kaptureDiv = document.getElementsByClassName('faq-parent-container');
      kaptureDiv[0]?.setAttribute('style', 'display:z-index: 99999; inset: auto 30px 30px auto;');

      // Create a new script element with additional attributes
      const newScript = document.createElement('script');
      if (newScript) {
        newScript.type = 'text/javascript';
        newScript.id = 'kap-snippet';
        newScript?.setAttribute('data-supportkey', kaptureDataSupportKey as string);
        newScript.setAttribute('chat-for', 'TICKET');
        if (environment === 'prod' || environment === 'staging')
          newScript.src = 'https://selfserveapp.kapturecrm.com/cb-v1/js/init_chat_local.js';
        else newScript.src = 'https://selfserveapp.kapturecrm.com/js/init_chat.js';
        newScript.setAttribute('data-server', 'Indian');
        if (mode === MiscellaneousEnums.LOGGED_IN) {
          newScript.setAttribute(
            'data-customercode',
            getCookies(KaptureEnums.KAPTURE_CC) as string
          );
          newScript.setAttribute('data-iv', getCookies(KaptureEnums.KAPTURE_IV) as string);
          newScript.setAttribute('server-host', 'ms-noauth');
          newScript.setAttribute('chat-type', 'RNF');
        } else newScript.setAttribute('chat-type', 'NR');
        if (adHocAttribute.length > 0) {
          newScript.setAttribute(
            adHocAttribute[0],
            encodeURIComponent(
              slugTitleMap[adHocAttribute[1]] ? slugTitleMap[adHocAttribute[1]] : ''
            )
          );
        }
        document.body.appendChild(newScript);
      }
    }
  };
  return {
    kaptureCustomerAPI,
  };
}
