import { create } from 'zustand';

type slugTitleMap = {
  slugTitleMap: any;
  setSlugTitleMap: (data: any) => void;
};

const useSlugTitleMapFunction = create<slugTitleMap>((set) => ({
  slugTitleMap: {},
  setSlugTitleMap: (data: any) => {
    set({ slugTitleMap: data });
  },
}));

export default useSlugTitleMapFunction;
