/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
export enum PaymentTypes {
  PAYMENT_FAILED = 'payment_failed',
  GATEWAY_ERROR = 'GATEWAY_ERROR',
  PAYMENT_DESCRIPTION_FAILED = 'Payment failed',
}
export enum BooleanValue {
  TRUE = 'true',
  FALSE = 'false',
}
export enum LoginType {
  COOKIE = 'cookie',
}
export enum MiscellaneousEnums {
  LOGGED_IN = 'loggedIn',
}
export enum RouterEnmus {
  CATEGORY = 'category',
  COURSE = 'course',
  CATEGORY_SLUG = 'categorySlug',
  COURSE_SLUG = 'courseSlug',
}
export enum KaptureEnums {
  KAPTURE_CC = 'kaptureCC',
  KAPTURE_IV = 'kaptureIV',
}
export enum TooltipPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum Environment {
  DEVELOPMENT = 'dev',
  PRODUCTION = 'prod',
  STAGING = 'staging',
}
export enum CurrentDetailsEnums {
  CURRENT_COURSE = 'currentCourse',
}
export enum EnvEnums {
  STAGING = 'staging',
  PRODUCTION = 'prod',
  DEVELOPMENT = 'dev',
  LOCAL = 'local',
}

export enum Browser {
  Edge = 'Microsoft Edge',
  Chrome = 'Chrome',
  Firefox = 'Firefox',
  Safari = 'Safari',
  Opera = 'Opera',
  InternetExplorer = 'Internet Explorer',
}

export enum OS {
  MacOS = 'Mac OS',
  Windows10 = 'Windows 10',
  Windows8_1 = 'Windows 8.1',
  Windows8 = 'Windows 8',
  Windows7 = 'Windows 7',
  WindowsXP = 'Windows XP',
  Windows11 = 'Windows 11',
}
