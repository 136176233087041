import { createContext, useEffect, useMemo, useState } from 'react';
import { IUserProfileDetails } from '@/interfaces/common';
import {
  getAccessToken,
  removeAccessToken,
  logout,
  clearKeysFromLocalStorage,
} from '@/utils/helper/helperFunction';
import AuthApi from '@/api/auth';

type UserContextType = {
  user: IUserProfileDetails;
  setUser: React.Dispatch<React.SetStateAction<IUserProfileDetails>>;
  isLoggedIn: boolean;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const userState = {
  user: {
    _id: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    isdCode: '',
    email: '',
    verified: false,
    createdAt: '',
    img: { link: '', source: '' },
  },
  setUser: () => null,
  isLoggedIn: false,
  setIsLoggedIn: () => null,
  isLoading: false,
  setIsLoading: () => null,
};
const UserDetailsContext = createContext<UserContextType>(userState);

const iniialState = {
  _id: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  isdCode: '',
  email: '',
  verified: false,
  createdAt: '',
  img: { link: '', source: '' },
};

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<IUserProfileDetails>(iniialState);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getUserDetails = async (): Promise<IUserProfileDetails | string> => {
    setIsLoading(true);
    return new Promise((resolve) => {
      const token = getAccessToken();
      if (token) {
        if (!user.phoneNumber) {
          AuthApi.getUserProfile()
            .then((response) => {
              if (response.data) {
                setIsLoggedIn(true);
                setUser(response.data);
                setIsLoading(false);
                resolve(response.data);

                // for npf event
                localStorage.setItem('user_id', response.data._id);
              }
            })
            .catch(() => {
              setIsLoading(false);
              setIsLoggedIn(false);
              removeAccessToken();
              clearKeysFromLocalStorage();
              logout();
            });
        }
      } else {
        setIsLoading(false);
        setIsLoggedIn(false);
        setUser(iniialState);
        resolve('No token found');
      }
    });
  };

  useEffect(() => {
    const token = getAccessToken();
    if (!isLoggedIn || !token) getUserDetails();
  }, [isLoggedIn]);

  const userStore = useMemo(() => {
    return { user, setUser, isLoggedIn, setIsLoggedIn, isLoading, setIsLoading };
  }, [user, isLoggedIn, isLoading, setIsLoading]);

  return <UserDetailsContext.Provider value={userStore}>{children}</UserDetailsContext.Provider>;
};

export default UserDetailsContext;
