import { useContext, useEffect } from 'react';
import AuthApi from '@/api/auth';
import UserDetailsContext from '@/context/UserDetailsContext';
import { IUserProfileDetails } from '@/interfaces/common';
import {
  getAccessToken,
  removeAccessToken,
  logout,
  clearKeysFromLocalStorage,
} from '@/utils/helper/helperFunction';
import { addMoEngageUser, initialiseMoengage } from '@/utils/initialiseMoEngage';
import { kapturCustomerApiFlag } from '@/utils/common/env.constants';
import { BooleanValue } from '@/GlobalEnums/enums';
import useGetUserAllCourses from './useGetUserAllCourses';
import useKapture from './useKapture';

const useInitLogin = () => {
  const { isLoggedIn, setIsLoggedIn, setUser, user, setIsLoading, isLoading } =
    useContext(UserDetailsContext);
  const addMoengageUserFunction = async (response: any) => {
    await addMoEngageUser(response);
  };
  const { getUserCoursesFunction } = useGetUserAllCourses();
  const { kaptureCustomerAPI } = useKapture();
  const handleMoengage = async (response: any) => {
    const status = await initialiseMoengage();
    if (status === 'success') addMoengageUserFunction(response);
  };
  const getUserDetails = async (): Promise<IUserProfileDetails | string> => {
    setIsLoading(true);
    return new Promise((resolve) => {
      const token = getAccessToken();
      if (token) {
        if (!user.phoneNumber) {
          AuthApi.getUserProfile()
            .then(async (response) => {
              if (response.data) {
                handleMoengage(response);
                setIsLoggedIn(true);
                setUser(response.data);
                setIsLoading(false);
                resolve(response.data);
                getUserCoursesFunction();
                // this api will feed kapture with skills user data
                if (kapturCustomerApiFlag === BooleanValue.TRUE) kaptureCustomerAPI(response?.data);
              }
            })
            .catch(() => {
              setIsLoading(false);
              setIsLoggedIn(false);
              removeAccessToken();
              clearKeysFromLocalStorage();
              logout();
            });
        }
      } else {
        setIsLoading(false);
        setIsLoggedIn(false);
        setUser({
          _id: '',
          firstName: '',
          lastName: '',
          phoneNumber: '',
          isdCode: '',
          email: '',
          verified: false,
          createdAt: '',
          img: { link: '', source: '' },
        });
        resolve('No token found');
      }
    });
  };

  useEffect(() => {
    const token = getAccessToken();
    if (!isLoggedIn || !token) getUserDetails();
  }, [isLoggedIn]);

  return { isLoading, user };
};

export default useInitLogin;
